import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

import axios from 'axios'
import VueAxios from 'vue-axios'

Vue.use(VueAxios, axios)

Vue.config.productionTip = false

import VueToastr2 from 'vue-toastr-2'
window.toastr = require('toastr')
Vue.use(VueToastr2, {
    positionClass: "toast-top-center",
    progressBar: true,
    timeOut: 5000
})

import VModal from 'vue-js-modal'
Vue.use(VModal)

import { directive as onClickaway } from 'vue-clickaway'
Vue.directive('on-clickaway', onClickaway)

import VueClipboard from 'vue-clipboard2'
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard)

new Vue({
    data: () => ({
        wallet: {
            adress: 'Lcx23481QEfdo12395gfj234854982134jkfgjsdl',
            qrCodeUrl: '/images/qr.jpeg' // 256х256
        },
        user: null,
        isLoading: true
    }),
    methods: {
        getSettings() {
            return this.axios.get('/api/settings').then(response => {
                const { data } = response

                this.wallet.adress = data.settings.payment_wallet
                this.wallet.qrCodeUrl = data.settings.payment_wallet_qr
            })
        },
        getMe() {
            return this.axios.get('/api/me').then(async response => {
                const { data } = response
                const currentPath = this.$route.path

                if(!data.user) {
                    if(currentPath !== '/login') {
                        await this.$router.push('/login')
                    }
                    return
                }

                if(currentPath === '/login') {
                    await this.$router.push('/')
                }

                this.user = data.user
            })
        },
        async init() {
            await Promise.all([this.getMe(), this.getSettings()])
            this.isLoading = false
        }
    },
    mounted() {
        this.init()
    },
    router,
    render: h => h(App)
}).$mount('#app')
