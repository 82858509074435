import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/Index.vue')
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/Transactions.vue')
    },
    {
        path: '/disputes',
        name: 'disputes',
        component: () => import('@/views/Disputes.vue')
    },
    {
        path: '/requisites',
        name: 'requisites',
        component: () => import('@/views/Requisites.vue')
    },
    {
        path: '/devices',
        name: 'devices',
        component: () => import('@/views/Devices.vue')
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('@/views/Messages.vue')
    },
    {
        path: '/withdraws',
        name: 'withdraws',
        component: () => import('@/views/Withdraws.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/profile',
        name: 'profile',
        component: () => import('@/views/Profile.vue')
    },
    {
        path: '/admin',
        name: 'admin',
        component: () => import('@/views/admin/Index.vue')
    },
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
