<template>
    <div id="app">
        <template v-if="!$root.isLoading">
            <Header/>
            <main class="pt-[107px] relative"> 
                <div class="wrapper py-6">
                    <router-view></router-view>
                </div>
            </main>
            <Modals/>
        </template>
    </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Modals from '@/components/Modals.vue'

export default {
    components: {
        Header,
        Modals
    }
}
</script>