<template>
    <header v-if="show" class="header">
        <div class="wrapper header__inner">
            <nav class="header__nav">
                <router-link tag="button" exact active-class="active" exact-active-class="active" :to="item.url" v-for="(item, index) in menu" :key="index">
                    <img :src="item.icon" alt="">
                    <span>{{ item.name }}</span>
                </router-link>
            </nav>
            <div class="header__right">
                <div class="header__balance">
                    <div class="header__balance-inner">
                        <b>Баланс</b>
                        <div class="header__balance-values">
                            <span>{{ parseFloat($root.user.balance).toFixed(2) }} USDT</span>
                            <hr>
                            <span>0 ₽</span>
                        </div>
                    </div>
                    <button type="button" @click="$modal.show('wallet')" class="header__balance-add">
                        <img src="/images/icons/svg7.svg" alt="">
                    </button>
                </div>
                <router-link to="/profile" tag="button" class="header__user">
                    <img src="/images/icons/svg8.svg" class="w-6 h-6" alt="">
                    <div class="header__user-inner">
                        <span>Мой профиль</span>
                        <p>ID: {{ $root.user.id }}</p>
                    </div>
                    <button type="button" class="header__user-exit" @click="logout">
                        <img src="/images/icons/svg9.svg" alt="">
                    </button>
                </router-link>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        data: () => ({
            menu: [
                { icon: '/images/icons/svg0.svg', name: 'Дашборд', url: '/' },
                { icon: '/images/icons/svg1.svg', name: 'Сделки', url: '/transactions' },
                { icon: '/images/icons/svg2.svg', name: 'Споры', url: '/disputes' },
                { icon: '/images/icons/svg3.svg', name: 'Реквизиты', url: '/requisites' },
                { icon: '/images/icons/svg4.svg', name: 'Устройства', url: '/devices' },
                { icon: '/images/icons/svg5.svg', name: 'Сообщения', url: '/messages' },
                { icon: '/images/icons/svg6.svg', name: 'Выплаты', url: '/withdraws' },
            ],
            show: true,
        }),
        methods: {
            checkUrl() {
                if(this.$router.currentRoute.path === '/login' || this.$router.currentRoute.path === '/admin') {
                    this.show = false
                } else {
                    this.show = true
                }
            },
            logout() {
                location.href = '/api/logout'
            }
        },
        mounted() {
            this.checkUrl()
        },
        watch: {
            $route (from, to) {
                this.checkUrl()
            }
        }
    }
</script>